// Interesting alchemical symbols
const alchemicalSymbols = [
    '&#x1F701;',
    '&#x1F702;',
    '&#x1F703;',
    '&#x1F704;',
    '&#x1F70D;',
    '&#x1F70E;',
    '&#x1F71D;',
    '&#x1F71E;',
    '&#x1F725;',
    '&#x1F731;',
    '&#x1F73F;',
    '&#x1F741;',
    '&#x1F745;',
    '&#x1F74A;',
    '&#x1F74B;',
    '&#x1F754;',
    '&#x1F755;',
    '&#x1F756;',
    '&#x1F764;',
    '&#x1F718;',
    '&#x1F71B;',
    '&#x1F720;',
    '&#x1F72B;',
    '&#x1F723;'
]

// Creates 2D HTML links that are draggable
export default class linkFactory {
    constructor(experience) {
        this.experience = experience

        // Parse list of links from HTML
        // document.querySelectorAll('#links a').forEach(link => {
        //     this.addLink(link.innerText, link.href)
        // })
    }

    addLink(name, url) {
        console.log(name, url)

        document.getElementById('sigil')?.remove()
        
        const padding = (1 - this.properties.scale)
        
        // Add to DOM
        document.body.insertAdjacentHTML('beforeend', `
        <svg id="sigil" viewBox="${-1 - padding} ${-1 - padding} ${2 + 2*padding} ${2 + 2*padding}" preserveAspectRatio="xMidYMid meet">
            <polygon points="-1,0.73 1,0.73 0,-1" />
        </svg>
        `)

        // Apply style
        document.querySelector('#sigil').style = `
            position: absolute;
            top: 0;
            left: 0;
            width: 100%;
            height: 100%;
        `
        document.querySelector('#sigil polygon').style = `
            fill: transparent;
            stroke: ${this.properties.color};
            stroke-width: ${this.properties.thickness}px;
        `
    }
   
}