import { sigilSettings } from "../settings.js"

// 2D HTML sigil element
export default class Sigil {
    constructor(experience) {
        this.experience = experience
        this.properties = Object.assign({}, sigilSettings)

        // Debug GUI
        if (this.experience.gui) {
            this.buildGUI()
        }

        this.addHTML()
    }

    buildGUI() {
        this.gui = this.guiFolder = this.experience.gui.addFolder('Sigil').close()
        this.gui.addColor(this.properties, 'color').onChange(() => { this.addHTML() })
        this.gui.add(this.properties, 'thickness').min(0).max(0.05).step(0.001).onChange(() => { this.addHTML() })
        this.gui.add(this.properties, 'scale').min(0).max(1).step(0.01).onChange(() => { this.addHTML() })
        this.gui.add(this.properties, 'perspective').min(100).max(800).step(1)
        this.gui.add(this.properties, 'rotation').min(0).max(.2).step(0.01)
    }

    addHTML() {
        document.getElementById('sigil')?.remove()
        
        const padding = (1 - this.properties.scale)
        
        // Add to DOM
        document.body.insertAdjacentHTML('beforeend', `
        <svg id="sigil" viewBox="${-1 - padding} ${-1 - padding} ${2 + 2*padding} ${2 + 2*padding}" preserveAspectRatio="xMidYMid meet">
            <polygon points="-1,0.73 1,0.73 0,-1" />
        </svg>
        `)

        // Apply style
        document.querySelector('#sigil').style = `
            position: absolute;
            top: 0;
            left: 0;
            width: 100%;
            height: 100%;
        `
        document.querySelector('#sigil polygon').style = `
            fill: transparent;
            stroke: ${this.properties.color};
            stroke-width: ${this.properties.thickness}px;
        `
    }

    update() {
        const rotY = this.experience.mouse.x * this.properties.rotation * 45
        const rotX = this.experience.mouse.y * this.properties.rotation * 45
        document.getElementById('sigil').style.transform = `perspective(${this.properties.perspective}px) rotateX(${rotX}deg) rotateY(${rotY}deg)`
    }
   
}