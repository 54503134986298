// import * as THREE from 'three'
import { sceneSettings } from '../settings.js'

export default class Environment {
    constructor(experience) {
        this.experience = experience
        this.scene = this.experience.scene
        this.resources = this.experience.resources
        this.gui = this.experience.gui

        // Debug GUI
        if (this.gui) {
            this.guiFolder = this.gui.addFolder('Lighting').close()
        }

        this.setAmbientLight()
        this.setPointLight()
    }

    setAmbientLight() {
        this.ambientLight = new THREE.AmbientLight('#ffffff', sceneSettings.ambientLightIntensity)
        this.scene.add(this.ambientLight)

        // Debug GUI
        if (this.gui) {
            this.guiFolder
                .add(this.ambientLight, 'intensity')
                .name('ambientLightIntensity')
                .min(0)
                .max(1)
                .step(0.001)
        }
    }

    setPointLight() {
        this.pointLight = new THREE.PointLight('#ffffff', sceneSettings.pointLightIntensity)
        this.pointLight.castShadow = true
        this.pointLight.shadow.camera.far = 15
        this.pointLight.shadow.mapSize.set(1024, 1024)
        this.pointLight.shadow.normalBias = 0.05
        this.pointLight.position.set(sceneSettings.pointLightX, sceneSettings.pointLightY, sceneSettings.pointLightZ)
        this.scene.add(this.pointLight)

        // Debug GUI
        if (this.gui) {
            this.guiFolder
                .add(this.pointLight, 'intensity')
                .name('pointLightIntensity')
                .min(0)
                .max(2)
                .step(0.001)

            this.guiFolder
                .add(this.pointLight.position, 'x')
                .name('pointLightX')
                .min(-5)
                .max(5)
                .step(0.001)

            this.guiFolder
                .add(this.pointLight.position, 'y')
                .name('pointLightY')
                .min(-5)
                .max(5)
                .step(0.001)

            this.guiFolder
                .add(this.pointLight.position, 'z')
                .name('pointLightZ')
                .min(0)
                .max(10)
                .step(0.001)
        }
    }

}