const sceneSettings = {
    "eyeZPosition": -2.65,
    "eyeYPosition": -0.2,
    "ambientLightIntensity": 0.035,
    "pointLightIntensity": 0.35,
    "pointLightX": 2,
    "pointLightY": 2.2,
    "pointLightZ": 3
}

// Zendo dragon eye
const eyeSettings = {
    "baseColor1": "#212121",
    "baseColor2": "#6d9886",
    "baseColor3": "#ffffff",
    "baseColor4": "#ffffff",
    "innerColor": "#d9cab3",
    "fiberColor": "#f6f6f6",
    "baseTextureType": "Piggy",
    "baseTextureFrequency": 0.52,
    "baseParamA": 0.42,
    "baseParamB": 0.92,
    "baseParamC": 0.78,
    "baseAnimation": 0.36,
    "baseRadial": true,
    "baseSymmetry": "horizontal",
    "innerRingType": "outer",
    "innerRingSize": 0.21,
    "innerRingSpread": 0,
    "dragonPupil": true,
    "fiberType": "High Voltage",
    "fiberFrequency": 0.3,
    "fiberIntensity": 0.17,
    "fiberParamA": 0.5,
    "fiberAnimation": 0.2,
    "fiberRadial": false,
    "fiberSymmetry": "both",
    "reflectivityMaterial": "matte"
}

const eyeAnimSettings = {
    "eyeMovementMinPeriod": 3,
    "eyeMovementMaxPeriod": 5,
    "eyeMovementProbability": 0.33,
    "eyeMovementRange": 0.2,
    "eyeHoldTimeMin": 0.4,
    "eyeHoldTimeMax": 2,
    "blinkMinPeriod": 10,
    "blinkMaxPeriod": 20,
    "blinkProbability": 0.5,
    "dilationMinPeriod": 20,
    "dilationMaxPeriod": 30,
    "dilationProbability": 0.5,
    "dilationHoldTimeMin": 3,
    "dilationHoldTimeMax": 10,
    "controlSensitivity": 0.1
}

const sigilSettings = {
    "color": "#ffffff",
    "thickness": 0.005,
    "scale": 0.95,
    "perspective": 600,
    "rotation": 0.02
}

const controlSettings = {
    "controlSensitivity": 0.25,
    "controlDamp": 0.35
}

export { eyeSettings, eyeAnimSettings, sceneSettings, sigilSettings, controlSettings }