// import * as THREE from 'three'
import { eyeAnimSettings } from '../settings.js'

export default class EyeAnimator {
    constructor(eye) {
        this.eye = eye
        this.experience = this.eye.experience

        this.properties = Object.assign({}, eyeAnimSettings)
        this.buildGUI()

        this.mixer = new THREE.AnimationMixer(this.eye.mesh)
        
        this.nextEyeMovement = this.randRange(this.properties.eyeMovementMinPeriod, this.properties.eyeMovementMaxPeriod)
        this.nextBlink = this.randRange(this.properties.blinkMinPeriod, this.properties.blinkMaxPeriod)
        this.nextDilation = this.randRange(this.properties.dilationMinPeriod, this.properties.dilationMaxPeriod)
        this.blinkAction = null
        this.eyeMoveAction = null
        this.eyeDilateAction = null

        this.experience.time.on('tick', () => {
            this.update()
        })
    }

    buildGUI() {
        this.gui = this.experience.gui
    
        const animationFolder = this.gui.addFolder('Animation').close()
        animationFolder.add(this.properties, 'eyeMovementRange').min(0).max(0.5).step(.01)
        animationFolder.add(this.properties, 'eyeMovementMinPeriod').min(1).max(30).step(1)
        animationFolder.add(this.properties, 'eyeMovementMaxPeriod').min(1).max(30).step(1)
        animationFolder.add(this.properties, 'eyeMovementProbability').min(0).max(1).step(.01)
        animationFolder.add(this.properties, 'eyeHoldTimeMin').min(0).max(5).step(.01)
        animationFolder.add(this.properties, 'eyeHoldTimeMax').min(0).max(5).step(.01)
        animationFolder.add(this.properties, 'blinkMinPeriod').min(1).max(30).step(1)
        animationFolder.add(this.properties, 'blinkMaxPeriod').min(1).max(30).step(1)
        animationFolder.add(this.properties, 'blinkProbability').min(0).max(1).step(.01)
        animationFolder.add(this.properties, 'dilationMinPeriod').min(1).max(50).step(1)
        animationFolder.add(this.properties, 'dilationMaxPeriod').min(1).max(50).step(1)
        animationFolder.add(this.properties, 'dilationProbability').min(0).max(1).step(.01)
        animationFolder.add(this.properties, 'dilationHoldTimeMin').min(0).max(5).step(.01)
        animationFolder.add(this.properties, 'dilationHoldTimeMax').min(0).max(5).step(.01)
        animationFolder.add(this.properties, 'controlSensitivity').min(0).max(0.5).step(.01)
    }

    // Smoothly move the eye from center to specified x,y rotation, wait t, then return to center
    eyeTo(x, y, t = 1) {
        if (this.eyeMoveAction) {
            this.eyeMoveAction.stop()
        }
        this.mixer.uncacheAction(this.eyeMoveAction)

        const moveTime = this.randRange(0.1, 0.2)
        const times = [0, 0.5 * moveTime, moveTime]
        const xValues = [0, 0.5 * x, x]
        const yValues = [0, 0.5 * y, y]
        const xTrack = new THREE.NumberKeyframeTrack('.rotation[y]', times, xValues, THREE.InterpolateSmooth)
        const yTrack = new THREE.NumberKeyframeTrack('.rotation[x]', times, yValues, THREE.InterpolateSmooth)
        const clip = new THREE.AnimationClip(null, moveTime + t, [xTrack, yTrack])
        this.eyeMoveAction = this.mixer.clipAction(clip)
        this.eyeMoveAction.setLoop(THREE.LoopPingPong, 2)

        this.eyeMoveAction.play()
        this.nextEyeMovement += 2 * (moveTime + t)
    }

    // Generate blink animation (hack using material opacity)
    blink() {
        if (this.blinkAction) {
            this.blinkAction.reset()
        } else {
            const blinkTime = 0.15
            const blinkHoldTime = 0.03
            const blinkTrack = new THREE.NumberKeyframeTrack('.material[opacity]', [0, 0.5 * blinkTime, blinkTime], [1, 0.5, 0], THREE.InterpolateSmooth)
            this.blinkAction = this.mixer.clipAction(new THREE.AnimationClip('blink', blinkTime + blinkHoldTime, [blinkTrack]))
            this.blinkAction.setLoop(THREE.LoopPingPong, 2)
        }
        this.blinkAction.play()
    }

    // Pupil dilation animation (hack using aoMapIntensity)
    // amt is amount to dilate (-1 to +1), t is hold time before returning to normal
    dilateTo(amt, t = 1) {
        if (this.eyeDilateAction) {
            this.eyeDilateAction.stop()
        }
        this.mixer.uncacheAction(this.eyeDilateAction)

        const dilateTime = this.randRange(3, 5)
        const times = [0, 0.5 * dilateTime, dilateTime]
        const values = [0, 0.5 * amt, amt]
        const track = new THREE.NumberKeyframeTrack('.material[aoMapIntensity]', times, values, THREE.InterpolateSmooth)
        const clip = new THREE.AnimationClip(null, dilateTime + t, [track])
        this.eyeDilateAction = this.mixer.clipAction(clip)
        this.eyeDilateAction.setLoop(THREE.LoopPingPong, 2)

        this.eyeDilateAction.play()
        this.nextDilation += 2 * (dilateTime + t)
    }

    // Generate random number in specified range
    randRange(min, max) {
        return Math.random() * (max - min) + min;
    }

    update() {
        const elapsed = this.experience.time.elapsed / 1000

        // TODO - eye follows cursor, unless no touch events in which case random (on mobile)

        // Eye follows cursor
        this.eye.mesh.rotation.y = (this.experience.mouse.x - this.eye.mesh.position.x) * this.properties.controlSensitivity
        this.eye.mesh.rotation.x = (-this.experience.mouse.y + this.eye.mesh.position.y) * this.properties.controlSensitivity

        // Random eye movement
        // if (elapsed > this.nextEyeMovement) {
        //     this.nextEyeMovement = elapsed + this.randRange(this.properties.eyeMovementMinPeriod, this.properties.eyeMovementMaxPeriod)
    
        //     if (Math.random() < this.properties.eyeMovementProbability) {
        //         this.eyeTo(this.randRange(-this.properties.eyeMovementRange, this.properties.eyeMovementRange), this.randRange(-this.properties.eyeMovementRange, this.properties.eyeMovementRange), this.randRange(this.properties.eyeHoldTimeMin, this.properties.eyeHoldTimeMax))
        //     }
        // }
    
        if (elapsed > this.nextBlink) {
            this.nextBlink = elapsed + this.randRange(this.properties.blinkMinPeriod, this.properties.blinkMaxPeriod)
    
            if (Math.random() < this.properties.blinkProbability) {
                this.blink()
            }
        }
    
        if (elapsed > this.nextDilation) {
            this.nextDilation = elapsed + this.randRange(this.properties.dilationMinPeriod, this.properties.dilationMaxPeriod)
    
            if (Math.random() < this.properties.dilationProbability) {
                this.dilateTo(this.randRange(-1, 1), this.randRange(this.properties.dilationHoldTimeMin, this.properties.dilationHoldTimeMax))
            }
        }

        this.mixer.update(this.experience.time.delta/1000)
    }

    destroy() {
        this.mixer.stopAllAction()
        this.mixer.destroy()
    }
}