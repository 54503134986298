// import * as THREE from 'three'

import Sizes from './Utils/Sizes.js'
import Time from './Utils/Time.js'
import EventEmitter from './Utils/EventEmitter.js'
import Camera from './Camera.js'
import Renderer from './Renderer.js'
import World from './World/World.js'
// import ARButton from './Utils/ARButton.js'

export default class Experience extends EventEmitter {
    constructor(_canvas, gui) {
        super()

        // Global access (most recent one overwrites)
        window.experience = this

        this.canvas = _canvas

        this.buildGUI(gui)

        // Setup 3D environment
        this.sizes = new Sizes()
        this.time = new Time()
        this.scene = new THREE.Scene()
        this.camera = new Camera(this)
        this.renderer = new Renderer(this)
        this.world = new World(this)

        // Web XR
        if (typeof ARButton !== 'undefined') {
            this.ar = ARButton.createButton(this.renderer, this.scene, '#000000')
            if (this.ar) {
                document.body.appendChild(this.ar)
                this.renderer.instance.xr.enabled = true
            }
        }

        // Resize event
        this.sizes.on('resize', () => {
            this.resize()
        })

        // Time tick event
        this.time.on('tick', () => {
            this.update()
        })

        // Mouse handlers
        this.canvas.style.touchAction = 'none'
        this.mouse = new THREE.Vector2()
        this.mouseDown = false
        window.addEventListener('pointermove', (event) => {
            // Calculate mouse position in normalized coordinates
            const centerX = window.innerWidth / 2
            const centerY = window.innerHeight / 2
            const offsetX = (window.innerWidth - this.sizes.width) / 2
            this.mouse.x = (event.clientX - centerX) / (this.sizes.width + offsetX - centerX)
            this.mouse.y = -(event.clientY - centerY) / (this.sizes.height - centerY)
            this.trigger('mouseMove')
        })
        window.addEventListener('pointerout', (event) => {
            // Set mouse out of viewport
            this.mouse.x = 0
            this.mouse.y = 0
            this.trigger('mouseOut')
        })
        window.addEventListener('pointerdown', (event) => {
            this.mouseDown = true
            this.trigger('mouseDown')
        })
        window.addEventListener('pointercancel', (event) => {
            this.mouseDown = false
            this.trigger('mouseUp')
        })
        window.addEventListener('pointerup', (event) => {
            this.mouseDown = false
            this.trigger('mouseUp')
        })
    }

    buildGUI(gui) {
        if (gui) {
            this.gui = gui.ui        
            // TODO - any top level gui stuff?
        } else {
            this.gui = null
        }
    }

    resize() {
        this.camera.resize()
        this.renderer.resize()
    }

    update() {
        this.camera.update()
        this.world.update()
        this.renderer.update()
    }

    destroy() {
        this.sizes.off('resize')
        this.time.off('tick')

        // Traverse the whole scene
        this.scene.traverse((child) => {
            // Test if it's a mesh
            if (child instanceof THREE.Mesh) {
                child.geometry.dispose()

                // Loop through the material properties
                for (const key in child.material) {
                    const value = child.material[key]

                    // Test if there is a dispose function
                    if (value && typeof value.dispose === 'function') {
                        value.dispose()
                    }
                }
            }
        })

        this.camera.controls.dispose()
        this.renderer.instance.dispose()

        if (this.gui)
            this.gui.ui.destroy()
    }

}
