// import * as THREE from 'three'
import eyeVertexDeclShader from '../shaders/eye/vertex_decl.glsl'
import eyeVertexMainShader from '../shaders/eye/vertex_main.glsl'
import eyeFragmentDeclShader from '../shaders/eye/fragment_decl.glsl'
import eyeFragmentMainShader from '../shaders/eye/fragment_main.glsl'
import EyeAnimator from './EyeAnimator.js'
import { eyeSettings, sceneSettings } from '../settings.js'


/** Constants */
const baseTextureTypes = [
    { name: 'Original', value: 1 },
    { name: 'Spotty', value: 2 },
    { name: 'Aqua', value: 3 },
    { name: 'Piggy', value: 4 },
    { name: 'High Voltage', value: 5 },
    { name: 'Blot', value: 6 },
    { name: 'Sparkle', value: 7 },
    { name: 'Gargoyle', value: 8 },
    { name: 'Geometric', value: 9 },
    { name: 'Acid', value: 10 },
    { name: 'Ceramic', value: 11 },
    { name: 'Mech', value: 12 }
]
const fiberTypes = [
    { name: 'none', value: 0 },
    { name: 'Original', value: 1 },
    { name: 'Wisp', value: 2 },
    { name: 'Strong', value: 3 },
    { name: 'Web', value: 4 },
    { name: 'Inverse', value: 5 },
    { name: 'Gargoyle', value: 6 },
    { name: 'High Voltage', value: 7 },
    { name: 'Graffiti', value: 8 }
]
const symmetryTypes = [
    { name: 'none', value: 0 },
    { name: 'horizontal', value: 1 },
    { name: 'vertical', value: 2 },
    { name: 'both', value: 3 },
]
const reflectivityTypes = [
    { name: 'matte', metalness: 0.0, roughness: 0.45 },
    { name: 'plastic', metalness: 0.0, roughness: 0.35 },
    { name: 'glass', metalness: 0.03, roughness: 0.2 }
]
const ringTypes = [
    { name: 'none', value: 0 },
    { name: 'inner', value: 1 },
    { name: 'outer', value: 2 },
]

export default class Eye {
    constructor(experience) {
        this.experience = experience
        this.scene = this.experience.scene
        this.properties = Object.assign({}, eyeSettings)

        this.customUniforms = {
            uTime: { value: 0 },

            uBaseColor1: { value: new THREE.Color(this.properties.baseColor1) },
            uBaseColor2: { value: new THREE.Color(this.properties.baseColor2) },
            uBaseColor3: { value: new THREE.Color(this.properties.baseColor3) },
            uBaseColor4: { value: new THREE.Color(this.properties.baseColor4) },
            uInnerColor: { value: new THREE.Color(this.properties.innerColor) },
            uFiberColor: { value: new THREE.Color(this.properties.fiberColor) },

            uBaseTextureType: { type: "i", value: baseTextureTypes.find(e => e.name == this.properties.baseTextureType).value },
            uBaseTextureFrequency: { value: this.properties.baseTextureFrequency },
            uBaseParamA: { value: this.properties.baseParamA },
            uBaseParamB: { value: this.properties.baseParamB },
            uBaseParamC: { value: this.properties.baseParamC },
            uBaseAnimation: { value: this.properties.baseAnimation },
            uBaseRadial: { value: this.properties.baseRadial ? 1 : 0 },
            uBaseSymmetry: { value: symmetryTypes.find(e => e.name == this.properties.baseSymmetry).value },

            uInnerRingType: { value: ringTypes.find(e => e.name == this.properties.innerRingType).value },
            uInnerRingSize: { value: this.properties.innerRingSize },
            uInnerRingSpread: { value: this.properties.innerRingSpread },
            uDragonPupil: { value: this.properties.dragonPupil ? 1 : 0 },

            uFiberType: { type: "i", value: fiberTypes.find(e => e.name == this.properties.fiberType).value },
            uFiberFrequency: { value: this.properties.fiberFrequency },
            uFiberIntensity: { value: this.properties.fiberIntensity },
            uFiberParamA: { value: this.properties.fiberParamA },
            uFiberAnimation: { value: this.properties.fiberAnimation },
            uFiberRadial: { value: this.properties.fiberRadial ? 1 : 0 },
            uFiberSymmetry: { value: symmetryTypes.find(e => e.name == this.properties.fiberSymmetry).value },
        }

        this.setGeometry()
        this.setMaterial()
        this.setMesh()

        this.animator = new EyeAnimator(this)

        this.buildGUI()
    }

    setGeometry() {
        this.geometry = new THREE.SphereGeometry(1, 32, 32, 0, Math.PI, 0, Math.PI)
    }

    updateUniforms() {
        if (!this.customUniforms)
            return
    
        this.customUniforms.uBaseColor1.value.set(this.properties.baseColor1)
        this.customUniforms.uBaseColor2.value.set(this.properties.baseColor2)
        this.customUniforms.uBaseColor3.value.set(this.properties.baseColor3)
        this.customUniforms.uBaseColor4.value.set(this.properties.baseColor4)
        this.customUniforms.uInnerColor.value.set(this.properties.innerColor)
        this.customUniforms.uFiberColor.value.set(this.properties.fiberColor)
    
        this.customUniforms.uBaseTextureType.value = baseTextureTypes.find(e => e.name == this.properties.baseTextureType).value
        this.customUniforms.uBaseTextureFrequency.value = this.properties.baseTextureFrequency
        this.customUniforms.uBaseParamA.value = this.properties.baseParamA
        this.customUniforms.uBaseParamB.value = this.properties.baseParamB
        this.customUniforms.uBaseParamC.value = this.properties.baseParamC
        this.customUniforms.uBaseAnimation.value = this.properties.baseAnimation
        this.customUniforms.uBaseRadial.value = this.properties.baseRadial ? 1 : 0
        this.customUniforms.uBaseSymmetry.value = symmetryTypes.find(e => e.name == this.properties.baseSymmetry).value
    
        this.customUniforms.uInnerRingType.value = ringTypes.find(e => e.name == this.properties.innerRingType).value
        this.customUniforms.uInnerRingSize.value = this.properties.innerRingSize
        this.customUniforms.uInnerRingSpread.value = this.properties.innerRingSpread
        this.customUniforms.uDragonPupil.value = this.properties.dragonPupil ? 1 : 0
    
        this.customUniforms.uFiberType.value = fiberTypes.find(e => e.name == this.properties.fiberType).value
        this.customUniforms.uFiberFrequency.value = this.properties.fiberFrequency
        this.customUniforms.uFiberIntensity.value = this.properties.fiberIntensity
        this.customUniforms.uFiberParamA.value = this.properties.fiberParamA
        this.customUniforms.uFiberAnimation.value = this.properties.fiberAnimation
        this.customUniforms.uFiberRadial.value = this.properties.fiberRadial ? 1 : 0
        this.customUniforms.uFiberSymmetry.value = symmetryTypes.find(e => e.name == this.properties.fiberSymmetry).value
    }

    setMaterial() {
        // Standard material for lighting effects, will inject custom shaders
        this.material = new THREE.MeshStandardMaterial({
            metalness: 0.0,
            roughness: 0.45,
            side: THREE.DoubleSide, // Doesn't work well with THREE.js r124
            transparent: true,
            opacity: 1, // HACK - I'm using this to represent blink amount
            aoMap: new THREE.DataTexture(Uint8Array.from([255, 255, 255]), 1, 1, THREE.RGBFormat), // Hack
            aoMapIntensity: 0, // HACK - I'm using this to represent pupil dilation amount
            depthWrite: false, // Using this to avoid display bugs with intersecting spheres
            defines: {
                IRIS_RADIUS: 0.5,
                PUPIL_RADIUS: 0.075
            }
        })

        // Inject custom uniforms and shaders
        this.material.onBeforeCompile = (shader) => {
            Object.assign(shader.uniforms, this.customUniforms)
            shader.vertexShader = shader.vertexShader.replace('#include <common>', '#include <common>\n' + eyeVertexDeclShader)
            shader.vertexShader = shader.vertexShader.replace('#include <begin_vertex>', '#include <begin_vertex>\n' + eyeVertexMainShader)
            shader.fragmentShader = shader.fragmentShader.replace('#include <common>', '#include <common>\n' + eyeFragmentDeclShader)
            shader.fragmentShader = shader.fragmentShader.replace('#include <color_fragment>', '#include <color_fragment>\n' + eyeFragmentMainShader)
        }
    }

    setMesh() {
        this.mesh = new THREE.Mesh(this.geometry, this.material)
        this.mesh.position.z = sceneSettings.eyeZPosition
        this.mesh.position.y = sceneSettings.eyeYPosition
        this.scene.add(this.mesh)
    }

    buildGUI() {
        this.gui = this.experience.gui

        // Position
        const posFolder = this.gui.addFolder('Positions').close()
        posFolder.add(this.mesh.position, 'z').min(-3).max(-1.75).step(0.01).name('Eye Z Position')
        posFolder.add(this.mesh.position, 'y').min(-1).max(1).step(0.01).name('Eye Y Position')
    
        // Colors
        const colorFolder = this.gui.addFolder('Colors').close()
        colorFolder.addColor(this.properties, 'baseColor1').onChange(() => { this.updateUniforms() })
        colorFolder.addColor(this.properties, 'baseColor2').onChange(() => { this.updateUniforms() })
        colorFolder.addColor(this.properties, 'baseColor3').onChange(() => { this.updateUniforms() })
        colorFolder.addColor(this.properties, 'baseColor4').onChange(() => { this.updateUniforms() })
        colorFolder.addColor(this.properties, 'innerColor').onChange(() => { this.updateUniforms() })
        colorFolder.addColor(this.properties, 'fiberColor').onChange(() => { this.updateUniforms() })
    
        // Base texture
        const textureFolder = this.gui.addFolder('Base Texture').close()
        textureFolder.add(this.properties, 'baseTextureType', baseTextureTypes.map(e => e.name)).name('Type').onChange(() => { this.updateUniforms() })
        textureFolder.add(this.properties, 'baseTextureFrequency').min(0).max(1).step(0.01).name('Frequency').onChange(() => { this.updateUniforms() })
        textureFolder.add(this.properties, 'baseParamA').min(0).max(1).step(0.01).name('Param A').onChange(() => { this.updateUniforms() })
        textureFolder.add(this.properties, 'baseParamB').min(0).max(1).step(0.01).name('Param B').onChange(() => { this.updateUniforms() })
        textureFolder.add(this.properties, 'baseParamC').min(0).max(1).step(0.01).name('Param C').onChange(() => { this.updateUniforms() })
        textureFolder.add(this.properties, 'baseAnimation').min(0).max(1).step(0.01).name('Animation Speed').onChange(() => { this.updateUniforms() })
        textureFolder.add(this.properties, 'baseRadial').name('Radial').onChange(() => { this.updateUniforms() })
        textureFolder.add(this.properties, 'baseSymmetry', symmetryTypes.map(e => e.name)).name('Mirror').onChange(() => { this.updateUniforms() })
    
        // Inner ring
        const ringFolder = this.gui.addFolder('Inner Ring').close()
        ringFolder.add(this.properties, 'innerRingType', ringTypes.map(e => e.name)).name('Type').onChange(() => { this.updateUniforms() })
        ringFolder.add(this.properties, 'innerRingSize').min(0).max(1).step(0.01).name('Size').onChange(() => { this.updateUniforms() })
        ringFolder.add(this.properties, 'innerRingSpread').min(0).max(1).step(0.01).name('Spread').onChange(() => { this.updateUniforms() })
        ringFolder.add(this.properties, 'dragonPupil').name('Dragon Pupil').onChange(() => { this.updateUniforms() })
    
        // Fibers
        const fiberFolder = this.gui.addFolder('Fibers').close()
        fiberFolder.add(this.properties, 'fiberType', fiberTypes.map(e => e.name)).name('Type').onChange(() => { this.updateUniforms() })
        fiberFolder.add(this.properties, 'fiberFrequency').min(0).max(1).step(0.01).name('Frequency').onChange(() => { this.updateUniforms() })
        fiberFolder.add(this.properties, 'fiberIntensity').min(0).max(1).step(0.01).name('Intensity').onChange(() => { this.updateUniforms() })
        fiberFolder.add(this.properties, 'fiberParamA').min(0).max(1).step(0.01).name('Param A').onChange(() => { this.updateUniforms() })
        fiberFolder.add(this.properties, 'fiberAnimation').min(0).max(1).step(0.01).name('Animation Speed').onChange(() => { this.updateUniforms() })
        fiberFolder.add(this.properties, 'fiberRadial').name('Radial').onChange(() => { this.updateUniforms() })
        fiberFolder.add(this.properties, 'fiberSymmetry', symmetryTypes.map(e => e.name)).name('Mirror').onChange(() => { this.updateUniforms() })
    
        // Eye reflectivity
        const reflectivityFolder = this.gui.addFolder('Eye Reflectivity').close()
        reflectivityFolder.add(this.properties, 'reflectivityMaterial', reflectivityTypes.map(e => e.name)).name('Material').onChange(() => {
            const refMaterial = reflectivityTypes.find(e => e.name == this.properties.reflectivityMaterial)
            this.material.metalness = refMaterial.metalness
            this.material.roughness = refMaterial.roughness
        })
    
        // After loading preset, make sure everything is set
        this.updateUniforms()
        const refMaterial = reflectivityTypes.find(e => e.name == this.properties.reflectivityMaterial)
        this.material.metalness = refMaterial.metalness
        this.material.roughness = refMaterial.roughness
    }
    

    update() {
        if (this.customUniforms) {
            this.customUniforms.uTime.value = this.experience.time.elapsed / 1000
        }

        this.animator.update()

    }

}