// import * as THREE from 'three'
import { sceneSettings, controlSettings } from './settings.js'

export default class Camera {
    constructor(experience) {
        this.experience = experience
        this.sizes = this.experience.sizes
        this.scene = this.experience.scene
        this.canvas = this.experience.canvas

        this.fov = 75

        this.setInstance()
        // this.setControls()

        // this.buildGUI()

        // I don't know why this fixes a weird texture webgl bug
        this.instance.lookAt(0, 0, 0)
    }

    setInstance() {
        this.instance = new THREE.PerspectiveCamera(this.fov, this.sizes.width / this.sizes.height, 0.1, 100)
        this.adjustFOV()
        this.instance.position.set(0, 0, 0)
        this.scene.add(this.instance)
    }

    adjustFOV() {
        // this.instance.aspect = this.sizes.width / this.sizes.height
        if (this.instance.aspect >= 1) {
            // landscape
            this.instance.fov = this.fov
        } else {
            // portrait
            const cameraHeight = Math.tan(THREE.MathUtils.degToRad(this.fov / 2))
            const newCameraHeight = cameraHeight / this.instance.aspect
            this.instance.fov = THREE.MathUtils.radToDeg(Math.atan(newCameraHeight)) * 2
        }
        this.instance.updateProjectionMatrix()
    }

    // setControls() {
    //     this.enableControls = false
    //     this.controlSensitivity = controlSettings.controlSensitivity
    //     this.controlDamp = controlSettings.controlDamp

    //     this.experience.on('mouseMove', () => {
    //         if (Math.abs(this.experience.mouse.x) <= 1 && Math.abs(this.experience.mouse.y) <= 1) {
    //             this.enableControls = true
    //         }
    //     })
    // }

    // buildGUI() {
    //     this.gui = this.experience.gui

    //     const controlsFolder = this.gui.addFolder('Controls').close()
    //     controlsFolder.add(this, 'controlSensitivity').min(0).max(3).step(0.01).name('Sensitivity')
    //     controlsFolder.add(this, 'controlDamp').min(0.01).max(0.5).step(0.01).name('Damping')
    // }

    resize() {
        this.instance.aspect = this.sizes.width / this.sizes.height
        this.adjustFOV()
    }

    update() {
        // Simple perspective shift with mouse movement
        // if (this.enableControls) {
        //     this.instance.position.x += (-this.experience.mouse.x * this.controlSensitivity - this.instance.position.x) * this.controlDamp
        //     this.instance.position.y += (-this.experience.mouse.y * this.controlSensitivity- this.instance.position.y) * this.controlDamp
        //     this.instance.lookAt(0, 0, sceneSettings.eyeZPosition)
        // } else {
        //     this.instance.position.set(0, 0, 0)
        //     this.instance.lookAt(0, 0, sceneSettings.eyeZPosition)
        // }
    }
}