import * as dat from 'lil-gui'

let instance = null

export default class GUI {
    constructor() {

        // Singleton
        if (instance !== null) {
            return instance
        }
        instance = this

        this.ui = new dat.GUI({ title: 'Debug Console [G]' })

        // Bind 'h' to show/hide
        window.addEventListener('keypress', (e) => {
            if (e.key === 'g') {
                const display = this.ui.domElement.style.display
                if (display === 'none') {
                    const display = this.ui.domElement.style.display = ''
                } else {
                    const display = this.ui.domElement.style.display = 'none'
                }
            }
        })

        this.ui.hide()
    }

}