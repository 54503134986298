import Environment from './Environment.js'
import Eye from './Eye.js'
import Sigil from './Sigil.js'
import LinkFactory from './LinkFactory.js'

export default class World {
    constructor(experience) {
        this.experience = experience
        this.scene = this.experience.scene

        // Setup 3D objects
        this.eye = new Eye(this.experience)
        this.environment = new Environment(this.experience)

        // Setup 2D HTML objects
        this.sigil = new Sigil(this.experience)
        this.linkFactory = new LinkFactory(this.experience)
    }

    update() {
        if (this.eye)
            this.eye.update()
        
        if (this.sigil)
            this.sigil.update()
    }
}