/**
 * To release:
 *  - Comment out GUI import to disable debug controls
 */
import './style.css'
import GUI from './Experience/Utils/GUI.js'
import Experience from './Experience/Experience.js'

const canvas = document.querySelector('canvas')
const gui = (typeof GUI === 'undefined') ? null : new GUI()

const experience = new Experience(canvas, gui)